import "./SurveyFailure.css";

export const SurveyFailure = () => {
  return (
      <>
          <div className='failure-content'>
              <i className="fa-regular fa-circle-check"></i>
              <div className='title'>Sorry error was happen, please try again later!</div>
          </div>
      </>
  )
}