export const statusSurvey = "survey";
export const statusSuccess = "success";
export const statusFailure = "failure";

export const answerTypeBoolean = "Checkbox";
export const answerTypeList = "List";
export const answerTypeNumber = "Number";
export const answerTypeRate = "Rate";
export const answerTypeString = "String";

const testApi = "http://localhost:5100/api/";
const prodApi = "https://letuscontact.orbiapps.com/api/"

export const currentApi = (process.env.NODE_ENV === "development") ? testApi : prodApi;