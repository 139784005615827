import './FeedbackButton.css';

export const FeedbackButton = (props) => {

    const {onClick, isSubmitting} = props;

    return (
        <div className={"feedback-button-container"}>
            <div className={"feedback-button-border"}>
                <button type={"button"} onClick={onClick}>
                    <span>Send Feedback</span>
                    <span style={{paddingLeft: 15}}>
                        {isSubmitting && <i className="fa fa-spinner fa-spin"></i>}
                        {!isSubmitting && <i className="fa-solid fa-thumbs-up"></i>}
                    </span>
                </button>
            </div>
        </div>
    )
}