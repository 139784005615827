import './App.css';
import {ruMessages} from "./lang/ru/ru";
import {kaMessages} from "./lang/ka/ka";
import {uaMessages} from "./lang/ua/ua";
import {arMessages} from "./lang/ar/ar";
import {enMessages} from "./lang/en/en";
import {useLanguage} from "./contexts/LanguageProvider";
import {IntlProvider} from "react-intl";
import {SurveyPanel} from "./components/pages/SurveyPanel";
import {MainLayout} from "./components/MainLayout";

function App() {

    const languages = {
        "en": enMessages,
        "ru": ruMessages,
        "ka": kaMessages,
        "ua": uaMessages,
        "ar": arMessages
    }

    const currentLanguage = useLanguage();

    return (
        <>
            <IntlProvider defaultLocale={"en"} locale={currentLanguage} messages={languages[currentLanguage]}>
                <SurveyPanel/>
            </IntlProvider>
        </>
    );
}

export default App;
