import {useState} from "react";
import {QuestionText} from "../shared/QuestionText";
import {Question} from "./Question";

export const ListQuestion = (props) => {

    const [selectedIndex, setSelectedIndex] = useState("0");

    const {question, mandatory, invalid, onSelectAnswer, linkedQuestions, linked} = props;
    const {AnswerVariants} = question;

    const [selectedLinkedQuestion, setSelectedLinkedQuestion] = useState(null);

    const handleSelectAnswer = (e) => {
        setSelectedIndex(e.target.value);
    }

    const handleSelectAnswerDiv = (index) => {
        setSelectedIndex(index);
        onSelectAnswer(index);
    }

    const handleChangeLinkedAnswer = (e) => {
        // console.log("answered linked:");
        // console.log(e);
        selectedLinkedQuestion.Answer = e;
        handleSelectAnswerDiv(e);
    }

    return (
        <>
            <QuestionText question={question} mandatory={mandatory} invalid={invalid} linked={linked}/>
            <div className={"row"}>
                <div className={"col-12 answers"}>
                    {AnswerVariants.map((answerVariant) =>
                        <div className={"row"} key={answerVariant.LineNumber}
                             onClick={() => handleSelectAnswerDiv(answerVariant.LineNumber)}>
                            <div className={"col-12 d-flex answer"}>
                                <input type="radio"
                                       value={answerVariant.LineNumber}
                                       checked={answerVariant.LineNumber === selectedIndex}
                                       onChange={handleSelectAnswer}
                                />
                                <div className="check"></div>
                                <label className={"h5"}>{answerVariant.AnswerText}</label>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {selectedLinkedQuestion &&
                <>
                    <Question Linked={true}
                              FeedbackQuestion={selectedLinkedQuestion.LinkedFeedbackQuestion}
                              Mandatory={selectedLinkedQuestion.Mandatory}
                              NotValidField={selectedLinkedQuestion.notValidField}
                              OnSelectAnswer={handleChangeLinkedAnswer}
                              LinkedQuestions={[]}
                    />
                </>
            }
        </>
    );
}