import {ListQuestion} from "./ListQuestion";
import {BooleanQuestion} from "./BooleanQuestion";
import {TextQuestion} from "./TextQuestion";
import {NumberRateQuestion} from "./NumberRateQuestion";
import {answerTypeBoolean, answerTypeList, answerTypeRate, answerTypeString} from "../../constants/DefaultValues";

export const Question = (props) => {

    const {FeedbackQuestion, Mandatory, NotValidField, LinkedQuestions, OnSelectAnswer, Linked} = props;

    if (FeedbackQuestion.AnswerType === answerTypeList) {
        return <ListQuestion question={FeedbackQuestion} mandatory={Mandatory}
                             invalid={NotValidField} linkedQuestions={LinkedQuestions}
                             onSelectAnswer={OnSelectAnswer} linked={Linked}/>
    } else if (FeedbackQuestion.AnswerType === answerTypeBoolean) {
        return <BooleanQuestion question={FeedbackQuestion} mandatory={Mandatory}
                                invalid={NotValidField} linkedQuestions={LinkedQuestions}
                                onSelectAnswer={OnSelectAnswer} linked={Linked}></BooleanQuestion>
    } else if (FeedbackQuestion.AnswerType === answerTypeString) {
        return <TextQuestion question={FeedbackQuestion} mandatory={Mandatory}
                             invalid={NotValidField} linkedQuestions={LinkedQuestions}
                             onSelectAnswer={OnSelectAnswer} linked={Linked}></TextQuestion>
    } else if (FeedbackQuestion.AnswerType === answerTypeRate) {
        return <NumberRateQuestion question={FeedbackQuestion} mandatory={Mandatory}
                                   invalid={NotValidField} linkedQuestions={LinkedQuestions}
                                   onSelectAnswer={OnSelectAnswer}></NumberRateQuestion>
    }

    return  (
        <></>
    );
}
