import {useState} from "react";
import './TextQuestion.css';
import {QuestionText} from "../shared/QuestionText";
import {Question} from "./Question";

export const TextQuestion = (props) => {
    const [result, setResult] = useState("");

    const {question, mandatory, invalid, linked, linkedQuestions, onSelectAnswer} = props;

    const [selectedLinkedQuestion, setSelectedLinkedQuestion] = useState(null);

    const handleChangeText = (e) => {
        setResult(e.target.value);
        onSelectAnswer(e.target.value);
    }

    const handleChangeLinkedAnswer = (e) => {
        // console.log("answered linked:");
        // console.log(e);
        selectedLinkedQuestion.Answer = e;
        handleChangeText(e);
    }

    return (
        <>
            <QuestionText question={question} mandatory={mandatory} invalid={invalid} linked={linked}/>
            <div className={"answers"}>
                <textarea value={result} onChange={handleChangeText}/>
            </div>
            {selectedLinkedQuestion &&
                <>
                    <Question Linked={true}
                              FeedbackQuestion={selectedLinkedQuestion.LinkedFeedbackQuestion}
                              Mandatory={selectedLinkedQuestion.Mandatory}
                              NotValidField={selectedLinkedQuestion.notValidField}
                              OnSelectAnswer={handleChangeLinkedAnswer}
                              LinkedQuestions={[]}
                    />
                </>
            }
        </>
    );
}