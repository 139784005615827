export const QuestionText = (props) => {

    const {question, mandatory, invalid, linked} = props;
    const {QuestionText} = question;

    return (
        <>
            <div className={"row question h5 p-2"}>
                <div className={"col-12" + (invalid ? " text-danger " : " text-white ")}>
                    {invalid && <i className="fa-solid fa-triangle-exclamation text-warning me-2"></i>}
                    <span>
                    {(linked ? "" : "") + QuestionText + (mandatory ? " * " : "")}
                </span>
                </div>
            </div>
        </>
    )
}