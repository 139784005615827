import { useEffect } from "react";
import "./SurveySuccess.css";

export const SurveySuccess = () => {
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
        window.location.href = "https://orbihotels.com";
    }, 5000);

    return () => {
        clearTimeout(timeoutHandler);
    };
  }, []);

  return (
    <>
      <div className="success-content">
        <i className="fa-regular fa-circle-check"></i>
        <div className="title">Thank you for your feedback!</div>
        <div className="text"></div>
      </div>
    </>
  );
};
