import {useEffect, useState} from "react";
import {ListQuestion} from "../questions/ListQuestion";
import {FeedbackButton} from "../FeedbackButton";
import {currentApi, statusFailure, statusSuccess, statusSurvey} from "../../constants/DefaultValues";
import {SurveySuccess} from "./SurveySuccess";
import {SurveyFailure} from "./SurveyFailure";
import {MainLayout} from "../MainLayout";
import {BooleanQuestion} from "../questions/BooleanQuestion";
import {TextQuestion} from "../questions/TextQuestion";
import {RateQuestion} from "../questions/RateQuestion";
import {OrbiLogo} from "../icons/OrbiLogo";
import useQueryParam from "../../hooks/use-query-param";
import {SurveyNotFound} from "./SurveyNotFound";
import {Loading} from "./Loading";
import {ErrorMessage} from "./ErrorMessage";
import {NumberRateQuestion} from "../questions/NumberRateQuestion";
import {Question} from "../questions/Question";


export const SurveyPanel = () => {

    const [surveyStatus, setSurveyStatus] = useState(statusSurvey);
    const [surveyData, setSurveyData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [surveyIsNotFound, setSurveyIsNotFound] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [surveyId, setSurveyId] = useQueryParam('surveyId', undefined);
    const [surveyProgress, setSurveyProgress] = useState(0);

    let answers = [];

    useEffect(() => {
        loadQuestions();
    }, []);

    useEffect(() => {
        const timer = null;
        if (surveyStatus === statusFailure) {
            const timer = setTimeout(() => {
                setSurveyStatus(statusSurvey);
            }, 3000);
        }
        return () => {
            if (timer) clearTimeout(timer);
        }
    }, [surveyStatus])

    const calculateProgress = () => {

        let totalQuestions = 0;
        let answeredQuestions = 0;

        surveyData.clientFeedbackData.Answers.map((answer) => {
            totalQuestions++;
            if ((answer.Answer)) {
                answeredQuestions++;
            }
        });

        if (totalQuestions === 0) {
            setSurveyProgress(0);
        } else {
            setSurveyProgress((answeredQuestions / totalQuestions * 100));
        }
    }

    const loadQuestions = async () => {
        setSurveyIsNotFound(false);
        setErrorMessage(null);
        setIsLoading(true);
        await new Promise(r => setTimeout(r, 500));

        try {
            const response = await fetch(`${currentApi}ClientFeedbacks/${surveyId}`);

            if (response.status === 404) {
                setSurveyIsNotFound(true);
                return;
            }

            if (response.status !== 200) {
                throw "Unknown error! Please try later!";
                return;
            }

            const responseData = await response.json();
            if (!responseData) {
                return;
            }

            const survey = {
                id: responseData.id,
                headerText: responseData.headerText,
                clientFeedbackData: JSON.parse(responseData.clientFeedbackData),
                status: responseData.status
            };
            if (survey.status === 1) {
                setSurveyIsNotFound(true);
                return;
            }

            setSurveyData(survey);
        } catch (e) {
            setErrorMessage("Unknown error! Please try later!");
        } finally {
            setIsLoading(false);
        }
    }

    const validateForm = () => {

        let isValid = true;

        surveyData.clientFeedbackData.Answers.map((answer) => {
            if (answer.Mandatory && (!answer.Answer)) {
                answer.notValidField = true;
                isValid = false;
            }
        });

        const newSurveyData = {...surveyData};
        setSurveyData(newSurveyData);

        return isValid;
    }

    const handleOnClickFeedback = async () => {

        setErrorMessage(null);
        setIsSubmitting(true);
        try {
            await new Promise(r => setTimeout(r, 500));

            if (!validateForm()) {
                window.scrollTo(0, 0);
                return;
            }

            const body = {
                id: surveyData.id,
                headerText: surveyData.headerText,
                clientFeedbackData: JSON.stringify(surveyData.clientFeedbackData),
                status: 1
            }

            const response = await fetch(`${currentApi}ClientFeedbacks`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body),
            });

            if (response.status !== 200) {
                throw "Unknown error";
                return;
            }

            setSurveyStatus(statusSuccess);
        } catch (e) {
            setErrorMessage("Submit Error!");
        } finally {
            setIsSubmitting(false);
        }
    }

    const handleOnSelectAnswer = (answer, value) => {
        // console.log(answer);
        // console.log(value);
        answer.Answer = value
        calculateProgress();
    }

    if (errorMessage) {
        return <MainLayout><ErrorMessage errorMessage={errorMessage}/></MainLayout>
    }

    if (surveyIsNotFound) {
        return <MainLayout><SurveyNotFound/></MainLayout>
    }

    if (surveyStatus === statusSuccess) {
        return <SurveySuccess/>
    }

    if (surveyStatus === statusFailure) {
        return <SurveyFailure/>
    }

    if (isLoading) {
        return <MainLayout><Loading/></MainLayout>
    }

    if (!surveyData) return <MainLayout><ErrorMessage errorMessage={"Error!"}/></MainLayout>

    const renderQuestions =
        surveyData.clientFeedbackData.Answers.map((answer, index) => {
            return <Question key={index}
                             FeedbackQuestion={answer.FeedbackQuestion}
                             Mandatory={answer.Mandatory}
                             NotValidField={answer.notValidField}
                             OnSelectAnswer={(value) => handleOnSelectAnswer(answer, value)}
                             LinkedQuestions={answer.LinkedQuestions}
            />
            // if (answer.FeedbackQuestion.AnswerType === answerTypeList) {
            //     return <ListQuestion key={index} question={answer.FeedbackQuestion} mandatory={answer.Mandatory}
            //                          invalid={answer.notValidField}
            //                          onSelectAnswer={(value) => handleOnSelectAnswer(answer, value)}/>
            // } else if (answer.FeedbackQuestion.AnswerType === answerTypeBoolean) {
            //     return <BooleanQuestion key={index} question={answer.FeedbackQuestion} mandatory={answer.Mandatory}
            //                             invalid={answer.notValidField}
            //                             onSelectAnswer={(value) => handleOnSelectAnswer(answer, value)}></BooleanQuestion>
            // } else if (answer.FeedbackQuestion.AnswerType === answerTypeString) {
            //     return <TextQuestion key={index} question={answer.FeedbackQuestion} mandatory={answer.Mandatory}
            //                          invalid={answer.notValidField}
            //                          onSelectAnswer={(value) => handleOnSelectAnswer(answer, value)}></TextQuestion>
            // } else if (answer.FeedbackQuestion.AnswerType === answerTypeRate) {
            //     return <NumberRateQuestion key={index} question={answer.FeedbackQuestion} mandatory={answer.Mandatory}
            //                          invalid={answer.notValidField} linkedQuestions={answer.LinkedQuestions}
            //                          onSelectAnswer={(value) => handleOnSelectAnswer(answer, value)}></NumberRateQuestion>
            // }
        });

    return (
        <MainLayout>
            <div className={"row mt-4"}>
                <div className={"col-12 h3"}>
                    {surveyData.headerText}
                </div>
            </div>
            <div className={"row mt-3 sticky-top"}>
                <div className={"col-12"}>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar"
                             style={{width: `${surveyProgress}%`, backgroundColor: "#1F4430"}}
                             aria-valuenow={surveyProgress}
                             aria-valuemin="0" aria-valuemax="100">{`${surveyProgress.toFixed(0)}%`}</div>
                    </div>
                </div>
            </div>
            <div className={"row mt-3"}>
                <div className={"col-12"}>
                    {renderQuestions}
                </div>
            </div>
            <div className={"row mt-5"}>
                <div className={"col-12 d-flex justify-content-center"}>
                    <FeedbackButton onClick={handleOnClickFeedback} isSubmitting={isSubmitting}/>
                </div>
            </div>
            <div className={"row my-5"}>
                <div className={"col-12"}>
                    <label>Fields marked with * are mandatory.</label>
                </div>
            </div>
        </MainLayout>
    )
}