export const Loading = () => {
  return (
      <>
          <div className={"row mt-5"}>
              <div className={"col-12 text-center"}>
                  <i className="fa fa-spinner fa-spin" style={{fontSize: "4em", color: "#1F4430"}}></i>
              </div>
          </div>
          <div className={"row"}>
              <div className={"col-12 text-center"}>
                  <b>Loading...</b>
              </div>
          </div>
      </>
  )
}