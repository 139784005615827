import './NumberRateQuestion.css';
import {Fragment, useEffect, useState} from "react";
import {QuestionText} from "../shared/QuestionText";
import {Question} from "./Question";

export const NumberRateQuestion = (props) => {

    const [result, setResult] = useState(undefined);
    const [rateDescription, setRateDescription] = useState("Rate here");
    const [rateBackgroundColor, setRateBackgroundColor] = useState("transparent");
    const [rateHovered, setRateHovered] = useState(false);
    const [hoverRate, setHoverRate] = useState(0);

    const {question, mandatory, invalid, linkedQuestions, linked, onSelectAnswer} = props;
    const {AnswerMaxRate} = question;

    const [selectedLinkedQuestion, setSelectedLinkedQuestion] = useState(null);


    // useEffect(() => {
    //     // console.log("number rate question data: ");
    //     // console.log(props);
    // }, []);

    let rateList = [];
    for (let index = AnswerMaxRate; index > 0; index--) {
        rateList.push(index);
    }

    // useEffect(() => {
    //     // console.log("selected linked question");
    //     // console.log(selectedLinkedQuestion);
    // }, [selectedLinkedQuestion])

    const handleChangeRate = (newRate) => {
        if (linkedQuestions) {
            const linkedQuestion = linkedQuestions.find((question) => {
                if (question.AnswerForLink === newRate) return true;
            });
            setSelectedLinkedQuestion(linkedQuestion);
        }
        setResult(newRate);
        onSelectAnswer(newRate);
    }

    const handleMouseOver = (rate) => {
        setHoverRate(rate);
        if (!rate) {
            setRateDescription("Rate here");
            setRateBackgroundColor("white");
            return;
        }
        const percent = (rate / rateList.length) * 100;
        if (percent <= 20) {
            setRateDescription("extremely dissatisfied");
            setRateBackgroundColor("red");
        } else if (percent <= 40) {
            setRateDescription("dissatisfied");
            setRateBackgroundColor("lightcoral");
        } else if (percent <= 60) {
            setRateDescription("neither");
            setRateBackgroundColor("#ffcc00");
        } else if (percent <= 80) {
            setRateDescription("satisfied");
            setRateBackgroundColor("lightgreen");
        } else if (percent <= 100) {
            setRateDescription("extremely satisfied");
            setRateBackgroundColor("#7CB342");
        } else {
            setRateDescription("Rate here");
            setRateBackgroundColor("white");
        }
    }

    const getLabelBackground = (rate, hovered) => {
        if (hovered) {
            if (rate <= hoverRate) return '#1F4430';
        } else {
            if (rate <= result) return '#1F4430';
        }
        return 'white';
    }

    const getLabelColor = (rate, hovered) => {
        if (hovered) {
            if (rate <= hoverRate) return 'white';
        } else {
            if (rate <= result) return 'white';
        }
        return 'black';
    }

    const calculateArrowPosition = () => {
        if (result) {
            return `${((100 / rateList.length) * result) - (100 / rateList.length / 2)}%`;
        }

        return 0;
    }

    const handleChangeLinkedAnswer = (e) => {
        // console.log("answered linked:");
        // console.log(e);
        selectedLinkedQuestion.Answer = e;
        handleChangeRate(result);
    }

    return (
        <>
            <QuestionText question={question} mandatory={mandatory} invalid={invalid} linked={linked}/>
            <div className={"rating"}
                 onMouseEnter={() => setRateHovered(true)}
                 onMouseLeave={() => setRateHovered(false)}
            >
                {rateList.map((rate, index) =>
                    <Fragment key={index}>
                        <input type={"radio"}
                               name={"rating"}
                               id={"rate" + rate}
                        />
                        <label htmlFor={"rate" + rate}
                               onMouseOver={() => handleMouseOver(rate)}
                               onMouseLeave={() => handleMouseOver(result)}
                               onClick={() => handleChangeRate(rate)}
                               style={{
                                   width: "" + (100 / rateList.length) + "vw",
                                   backgroundColor: getLabelBackground(rate, rateHovered),
                                   color: getLabelColor(rate, rateHovered)
                               }}>
                            {rate}
                        </label>
                    </Fragment>
                )}
            </div>
            <div>
                {/*{result &&*/}
                {/*    <div className={"rating-string-box-arrow"}*/}
                {/*         style={{left: '20vw', backgroundColor: 'red'}}>*/}
                {/*    </div>*/}
                {/*}*/}
                <div className="rating-string-box"
                     style={{backgroundColor: rateBackgroundColor, marginBottom: 10, width: "100%"}}>
                    <span style={{zIndex: 1}}>{rateDescription}</span>
                </div>
            </div>
            {selectedLinkedQuestion &&
                <>
                    <Question Linked={true}
                              FeedbackQuestion={selectedLinkedQuestion.LinkedFeedbackQuestion}
                              Mandatory={selectedLinkedQuestion.Mandatory}
                              NotValidField={selectedLinkedQuestion.notValidField}
                              OnSelectAnswer={handleChangeLinkedAnswer}
                              LinkedQuestions={[]}
                    />
                </>
            }
        </>
    )
}