export const ErrorMessage = (props) => {
    const {errorMessage} = props;

    return (
        <>
            <div className={"row mt-5"}>
                <div className={"col-12 text-center"}>
                    <i className="fa-solid fa-triangle-exclamation" style={{fontSize: "4em", color: "red"}}></i>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12 text-center"}>
                    <h1 style={{color: 'red'}}>{errorMessage}</h1>
                </div>
            </div>

        </>
    )
}