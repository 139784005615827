import {useState} from "react";
import {QuestionText} from "../shared/QuestionText";
import {Question} from "./Question";

export const BooleanQuestion = (props) => {
    const [result, setResult] = useState(undefined);

    const {question, mandatory, invalid, onSelectAnswer, linkedQuestions, linked} = props;

    const [selectedLinkedQuestion, setSelectedLinkedQuestion] = useState(null);

    const handleSelectAnswerDiv = (value) => {
        console.log(value);
        setResult(value);
        onSelectAnswer(value);
    }

    const handleChangeLinkedAnswer = (e) => {
        // console.log("answered linked:");
        // console.log(e);
        selectedLinkedQuestion.Answer = e;
        handleSelectAnswerDiv(e);
    }

    return (
        <>
            <QuestionText question={question} mandatory={mandatory} invalid={invalid} linked={linked}/>
            <div className={"answers"}>
                <div className={"row"}>
                    <div className={"col-12 answers"}>
                        <div className={"row"}
                             onClick={() => handleSelectAnswerDiv("yes")}>
                            <div className={"col-12 d-flex answer"}>
                                <input type="radio"
                                       value={"yes"}
                                       checked={result === "yes"}
                                       onChange={(e) => {
                                       }}
                                />
                                <div className="check"></div>
                                <label className={"h5"}>{"Yes"}</label>
                            </div>
                        </div>
                        <div className={"row"}
                             onClick={() => handleSelectAnswerDiv("no")}>
                            <div className={"col-12 d-flex answer"}>
                                <input type="radio"
                                       value={"no"}
                                       checked={result === "no"}
                                       onChange={(e) => {
                                       }}
                                />
                                <div className="check"></div>
                                <label className={"h5"}>{"No"}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {selectedLinkedQuestion &&
                <>
                    <Question Linked={true}
                              FeedbackQuestion={selectedLinkedQuestion.LinkedFeedbackQuestion}
                              Mandatory={selectedLinkedQuestion.Mandatory}
                              NotValidField={selectedLinkedQuestion.notValidField}
                              OnSelectAnswer={handleChangeLinkedAnswer}
                              LinkedQuestions={[]}
                    />
                </>
            }
        </>
    );
}