import {OrbiLogo} from "./icons/OrbiLogo";
import "./MainLayout.css";

export const MainLayout = (props) => {

    const { children } = props;

    return (
      <div className={"container"}>
          <div className={"row mt-5"}>
              <div className={"col-12 d-flex align-items-center justify-content-center"}>
                  <OrbiLogo/>
              </div>
          </div>
          <div className={"row"}>
              <div className={"col-12 text-center"}>
                  <div className={"display-6"}>Customer Satisfaction Survey</div>
              </div>
          </div>
          <div className={"row"}>
              <div className={"col-12"}>
                  {children}
              </div>
          </div>
      </div>
  )
}