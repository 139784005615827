import {MainLayout} from "../MainLayout";

export const SurveyNotFound = () => {
    return (
        <>
            <div className={"d-flex justify-content-center align-items-center text-center mt-5"}>
                <div className={"h2"}>Survey not found or you have already been completed your survey. Thank you!</div>
            </div>
        </>
    )
}